<template>
  <div>
    <FrontendNav
      v-if="$route.name !== 'landing-page.watchvideo'"
      :homeURL="{ name: 'dashboard.home-1' }"
    >
      <template slot="responsiveRight">
        <ul
          class="d-flex align-items-center justify-content-end list-inline m-0"
        ></ul>
      </template>
    </FrontendNav>
    <MainCategoryListItems />
  </div>
</template>

<script>
import MainCategoryListItems from "@/views/FrontendPages/Components/MainCategory/MainCategoryListItems.vue"
import FrontendNav from "@/components/core/navbars/FrontendNav.vue" // import SkeletonLoader from "@/views/FrontendPages/Components/ListItems/ItemSkeleton.vue"

export default {
  name: "MainCategoryLayout",
  components: {
    MainCategoryListItems,
    FrontendNav
  }
}
</script>

<style lang="scss">
@import url("../assets/css/custom.css");
@import url("../assets/css/developer.css");
@import "../assets/scss/frontend.scss";
</style>
