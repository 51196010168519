<template>
  <div class="container pt-5">
    <h3
      class="ps-5 text-center"
      data-delay-in="0.6"
      style="
        font-family: MyriadPro-Bold;
        text-align: start;
        align-content: end;
        height: 150px;
      "
    >
      {{
        $i18n.locale == "en"
          ? " All Categories"
          : $i18n.locale == "ku"
          ? "هەموو بەشەکان"
          : "جميع الفئات"
      }}
    </h3>
    <div
      v-if="loading"
      class="text-center align-self-end m-5"
      style="
        height: 200px;

        align-content: end;
      "
    >
      <b-spinner
        label="Loading ..."
        style="width: 3rem; height: 3rem"
        type="grow"
      ></b-spinner>
    </div>
    <div
      v-else-if="categories?.length === 0"
      class="text-center align-self-end m-5"
      style="
        height: 200px;

        align-content: end;
      "
    >
      No Data Found
    </div>
    <section v-else class="main">
      <div
        v-for="(item, index) in categories"
        :key="index"
        class="wrap wrap--1"
      >
        <router-link :to="`/${item.id}`">
          <div class="cardContainer">
            <div style="width: 128px; height: 128px">
              <img
                v-if="item.thumbnail != null"
                :src="item.thumbnail"
                alt=""
                class="img-fluid"
              />

              <img
                v-else
                alt=""
                class="img-fluid"
                src="../../../../assets/images/thumbnail.jpg"
              />
            </div>
            <div>
              <p class="border-top-primary">
                {{ item.name?.[$i18n.locale] }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
import { core } from "@/config/pluginInit"
import ApiService from "@/services/api"

export default {
  name: "MainCategoryListItems",
  components: {},
  mounted() {
    core.index()
    this.fetchMainCategories()
  },
  data() {
    return {
      loading: true
    }
  },
  methods: {
    fetchMainCategories() {
      ApiService.getMainCategories()
        .then(response => {
          this.categories = response.data.data
          this.$store.commit("setPoints", response.data.user_points)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss">
.main {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.wrap {
  margin: 32px 10px;

  cursor: pointer;
}

.cardContainer {
  border-radius: 1.6rem;
  padding: 0.5rem;

  background-position: var(--bX) var(--bY);
  box-shadow: 0 0 3rem 0.5rem hsla(0, 0%, 0%, 0.2);
}

.cardContainer p {
  border-top: 1px solid hsl(207.86deg 5.97% 19.1%);
  padding-top: 2px;
  text-align: center;
  color: hsla(0, 0%, 100%, 0.6);
}
</style>
